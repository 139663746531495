.Sentigel {
  text-align: center;
}
::selection {
  background: #2e4a24;
}
.Sentigel-logo {
  width: 50%;
  pointer-events: none;
}

.Sentigel-body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}
.Sentigel-contact {
  margin: 0;
}
a:link {
  color: black;
  text-decoration: none;
}
a:hover {
  color: #50b035;
}
@media only screen and (max-width: 1000px) {
  .Sentigel-logo {
    width: 70%;
  }
}
